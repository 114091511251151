.sectors{
    display: flex;;
}

.serviceSector {
    border: 1px solid grey;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    align-items: center;
}