body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.RaList-main, .RaLayout-content {
  width: 80vw;
  max-height: calc(100vh - 48px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.RaList-content {
  overflow: auto;
  flex: 1;
}

.add-filter {
  display: none !important;
}
.MuiTabs-flexContainer {
  max-width: max-content;
}

.MuiToolbar-root{
  min-height: unset;
}

.Kalend__CalendarDesktopNavigation__container,.Kalend__CalendarDesktopNavigation__container-mobile{
  display: none !important;
}
.Kalend__Calendar__root {
  overflow-x: initial !important;
  min-width: 200px !important;
}
.Kalend__CalendarBody {
  overflow: initial !important;
}
.Kalend__Calendar__table-surface {
  overflow: initial !important;
}
.Kalend__Calendar__table {
  overflow: initial !important;
}
.Kalend__CalendarBodyHours__line {
  max-width: 200px !important;
}
.fullwidth {
  width: 100% !important;
}
.ck-chip{
  border: 1px solid #1d1f26;
  border-radius: 4px;
  background: #fafafb;
  text-align: center;
  font-size: 13px;
  margin: 2px;
  color: rgb(0, 0, 0);
}

.displayFlex {
  display: flex;
}

.cursorPointer {
  cursor: pointer;
}

*{
  box-sizing: border-box;
}

.MuiTablePagination-root{
  overflow: visible !important;
}

.toolbar-custom-css .RaList-main div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular{
  flex-wrap: wrap;
    min-height: 24px;
    align-items: flex-end;
    justify-content: flex-end;
}
.toolbar-custom-css .RaList-main span.MuiTablePagination-root .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular{
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.toolbar-custom-css .RaList-main div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular form{
  flex: auto;
}

.Mui-disabled{
  color: #a3adb2 !important;
}
/* PreviewerImage.css */

.previewer-image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Image */
.previewer-image {
  /* width: auto; */
  height: 100%;
  max-height: 70vh;
  display: block;
}


