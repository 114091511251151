.custom-table {
    border: 1px solid #0000001a;
    border-collapse: collapse;
    padding: 72px;
    margin: 16px auto;
}
.custom-table > tr, td, th {
    border-collapse: collapse;
    padding: 15px;
    height: 100%;
    
}
