.input_field {
    margin-right: 20px !important;
    margin-bottom: 10px !important;
}

.visitTimeDivParent {
    margin-top: 15px;
    display: flex !important;
}

.footer {
    position: absolute;
    margin-top: 50px !important;
    bottom: 0;
}
.cook-slot-footer{
    width: 33%;
}

.cookSlotForm{
    max-width: 300px;
}

.partnerTimeSlotTable {
    margin-bottom: 100px;
}
