.wrapper {
  display: flex;
  flex-direction: column;
  background: dodgerblue;
}


.container__title {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.title {
  font-weight: bold;
  font-size: 4.2rem;
  color: white;
  margin: 12px 48px 20px 48px;
}

.subtitle {
  font-weight: bold;
  font-size: 1.7rem;
  color: white;
  margin: 0 0 48px;
}

.Calendar__wrapper {
  width: 100%;
  height: 100%;
  max-height: 60%;
  display: flex;
  max-width: 850px;
  margin: 0 auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
}

.rbc-calendar {
  width: 200px;
  height: 65vh !important; 
}


.rbc-label{
  font-size: 0.5rem !important;
}
.rbc-time-content::-webkit-scrollbar{
  display: none !important;  /* For Chrome, Safari, and Opera */
}
.rbc-calendar .rbc-time-slot {
  display: none;
}

/* Show time slots only for the first calendar */
.first-calendar .rbc-time-slot {
  display: block !important;
}
/* .rbc-time-view{
  border: none !important;
} */
.rbc-time-content > * + * > * {
  border-left: 1px !important;
}
.rbc-time-header{
  display: none !important;
}
.rbc-current-time-indicator{
  height: 2px !important;
  background-color: #d04e7a !important;
}

.rbc-event-label{
  font-size: 10px !important;
  font-weight: 500;

}
/* Decrease font size for event headings */
.rbc-event-content {
  font-size: 12px;
  font-weight: 500;
}

